import { graphql } from 'gatsby';
import React from 'react'
import UseCases from '../templates/use-cases';
import { imageDataType } from './prevent-escalations';

function TicketVolumeReduction({ data }: { data: imageDataType }) {
  const pageData = {
    seoData: {
      title: "Ticket Deflection: Reduce support tickets for Agent Productivity",
      canonicalLink: "https://www.aptedge.io/ticket-volume-reduction/",
      metaDescription: {
        metaDescription: `Learn How to reduce support tickets with Aptedge, an AI-powered ticketing automation platform. Increase agent productivity by 30% and enhance customer satisfaction.`,
      },
      openGraphImage: {
        file: {
          url: "",
        },
      },
    },
    title: "Ticket Volume Reduction",
    heroImage: data.heroImg.childImageSharp.gatsbyImageData,

    reasons: {
      title: "Why do you need to reduce ticket volume?",
      content: "Customer care leaders are facing a perfect storm of challenges: call volumes are up, employees are leaving and rising customer expectations.",
      image: data.reasonImg.childImageSharp.gatsbyImageData,
    },
    testimonial: [
      {
        company: "cisco",
        name: "Charles Coaxum",
        bio: "Former VP, Customer Experience",
        logo: data.testimonialLogoOne.childImageSharp.gatsbyImageData,
        content: "AptEdge provides fast, accurate answers to all customer problems. We’re bringing AptEdge to our entire company."
      },
      {
        company: "cloud bees",
        name: "Ryan Smith",
        bio: "Sr. Support Manager",
        logo: data.testimonialLogoTwo.childImageSharp.gatsbyImageData,
        content: "AptEdge has been key in bridging silos between tools we use on a daily basis to build software."
      },
    ],
    dummyImg: data.dummyImg.childImageSharp.gatsbyImageData,
    relatedCases: [
      {
        text: "Prevent Escalation",
        link: "/prevent-escalations/",
      },
      {
        text: "Improved CSAT",
        link: "/improved-csat/",
      },
      {
        text: "Improved Resolution Time",
        link: "/improved-resolution-time/",
      }
    ]
  }
  return (
    <UseCases pageData={pageData} />

  )
}

export default TicketVolumeReduction;

export const query = graphql`
    query TicketVolumenReasonImage {
        reasonImg: file(relativePath: { eq: "use-cases/reasons-ticket-volume.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE)
            }
        }
        testimonialLogoOne: file(relativePath: { eq: "utkarsh/cisco-logo.png" }) {
          childImageSharp {
              gatsbyImageData(placeholder: NONE)
          }
      }
      testimonialLogoTwo: file(relativePath: { eq: "utkarsh/logo-cloudbees.png" }) {
        childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
    }
        dummyImg: file(relativePath: { eq: "use-cases/dummy.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE)
        }
      }
        heroImg: file(relativePath: { eq: "use-cases/hero-dummy.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: NONE )
        }
        }
      }`